<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters, mapState } from 'vuex';
import CentralLoader from '@/components/Loaders/CentralLoader';
import TnC from '@/components/Themes/dayonedayone/Loyalty/TnC';
import dayjs from 'dayjs';

export default {
  name: 'Signup',
  props: ['visible'],
  components: {
    CentralLoader,
    TnC,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      loadedAt: null,
      client: null,
      signupPoints: 0,
      registerForm: {
        email: '',
        contactNo: '',
        firstName: '',
        lastName: '',
        dob: '',
        password: '',
        confirmation: '',
        merchantId: 0,
        tnc: false,
      },
      dialogTnC: false,
      attrs: [{
        key: 'selected',
        highlight: {
          class: 'vc-selected',
        },
        dates: new Date(),
      }],
      modelConfig: {
        timeAdjust: '00:00:00',
      },
      masks: {
        input: 'DD/MM/YYYY',
      },
      isLoading: false,
      show: false,
      error: null,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('client', {
      name: 'getName',
    }),
    dobMaxDate() {
      return dayjs().subtract(13, 'year').toDate();
    }
  },
  methods: {
    ...mapActions('client', {
      createClient: 'create',
    }),
    ...mapActions('data', ['createDataAnalyticEvent']),
    ...mapActions('log', ['createLog']),
    ...mapActions('loyalty', {
      createLoyalty: 'create',
    }),
    backToHome() {
      this.isLoading = true;
      window.location.href = '/';
    },
    viewTnC() {
      this.$gtag.event('view-tnc', {
        event_category: 'interaction',
        event_label: 'View Terms & Conditions',
      });

      this.registerForm.tnc = !this.registerForm.tnc;
      this.dialogTnC = true;
    },
    async cancel() {
      this.$gtag.event('sign-up-cancel', {
        event_category: 'interaction',
        event_label: 'Sign up (Cancel)',
        event_value: `Email: ${this.registerForm.email}, Contact No: ${this.registerForm.contactNo}`,
      });

      this.$router.push({ name: 'home' });
    },
    async signup() {
      this.isLoading = true;

      if (!(await this.$refs.form.validate())) {
        this.isLoading = false;
        return;
      }

      if (!this.registerForm.tnc) {
        this.error = 'Please tick to agree the terms & conditions.';
        setTimeout(() => this.error = null, 5000);
        this.isLoading = false;
        return;
      }

      try {
        this.$gtag.event('sign_up', {
          event_category: 'engagement',
          event_label: 'Sign up',
          event_value: `Email: ${this.registerForm.email}, Contact No: ${this.registerForm.contactNo}`,
        });

        this.registerForm.dob = dayjs(this.registerForm.dob).format('YYYY-MM-DD');
        this.client = await this.createClient(this.registerForm);

        this.createDataAnalyticEvent({
          event: 'signup-success',
          data: {
            hqMerchantId: this.profile.hq_merchant_id,
            merchantId: this.profile.merchant_id,
            refTable: 'client',
            refId: this.client.id,
            category: 'engagement',
            label: 'Time Used',
            value: dayjs().diff(this.loadedAt, 's'),
          },
        });
        
        if (this.client) {
          window.scrollTo(0,0);

          // join loyalty program
          this.signupPoints = (await this.createLoyalty({
            merchantId: this.profile.merchant_id,
          }))?.points ?? 0;

          this.createDataAnalyticEvent({
            event: 'join-success',
            data: {
              hqMerchantId: this.profile.hq_merchant_id,
              merchantId: this.profile.merchant_id,
              refTable: 'loyalty-clients',
              refId: this.client.id,
              category: 'engagement',
              label: 'Time Used',
              value: dayjs().diff(this.loadedAt, 's'),
            },
          });
        }

        this.isLoading = false;
      } catch (e) {
        this.createLog({
          type: 'error',
          data: {
            app: 'pwa',
            nav: 'signup.signup',
            merchantId: this.profile?.merchant_id ?? 0,
            message: e.message,
            refTable: 'uuid',
            refId: localStorage.uuid,
          }
        });

        console.log(e.message);
        this.isLoading = false;
        this.error = e.message;
        setTimeout(() => (this.error = null), 5000);
      }
    },
  },
  mounted() {
    this.registerForm.merchantId = this.profile.merchant_id;
    // this.registerForm.dob = dayjs().subtract(25, 'year').toDate();

    this.loadedAt = dayjs();
  },
};
</script>
<template>
  <v-sheet
    class="signup-header-sheet mx-auto pt-3"
    elevation="0"
    v-if="client"
  >
    <CentralLoader v-if="isLoading" color="white"></CentralLoader>
    <v-row class="m-0 py-2">
      <v-col cols="12">
        <h4 class="text-center white--text font-weight-bold">Welcome!</h4>
        <h2 class="text-center tertiary--text font-weight-bold mt-4">{{ name }}</h2>
        <v-dialog v-model="client" persistent transition="dialog-bottom-transition" @click:outside="backToHome()">
          <v-card @click="backToHome()">
            <v-img
              height="90dvh"
              :src="`${cdnUrl}/assets/images/loyalty/${profile.hq_merchant_id}/popup/signup-success.webp`"
            ></v-img>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-sheet>
  <v-sheet 
    class="signup-body-sheet mx-auto pt-6"
    elevation="0"
    v-else
  >
    <ValidationObserver ref="form">
      <form>
        <v-card color="#f7efe8" elevation="0">
          <v-card-title class="justify-center h4 font-weight-bold pb-2">
            <span style="color:#122944;">Sign-up</span> <span class="tertiary--text ml-2">as member</span>
          </v-card-title>
          <v-card-text class="text-center pb-3">
            <v-row class="px-3">
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Name"
                  :rules="{ required: true, min: 3, max: 30 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.firstName"
                    class="register-form p-0 m-0"
                    placeholder="Name"
                    label="Name"
                    color="secondary"
                    autocomplete="first-name"
                    height="30"
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Contact No"
                  :rules="{ required: true, regex: /^\d{10,13}$/ }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.contactNo"
                    class="register-form p-0 m-0"
                    placeholder="Contact Number"
                    label="Contact No"
                    color="primary"
                    autocomplete="contact-no"
                    height="30"
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.email"
                    class="register-form p-0 m-0"
                    placeholder="E-mail Address"
                    label="Email"
                    color="primary"
                    autocomplete="email"
                    height="30"
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <vc-date-picker
                  v-model="registerForm.dob"
                  mode="date"
                  color="primary"
                  :attributes="attrs"
                  :modelConfig="modelConfig"
                  :masks="masks"
                  :max-date="dobMaxDate"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      placeholder="Date of Birth"
                      class="register-form p-0 m-0"
                      v-on="inputEvents"
                      :value="inputValue"
                      append-icon="mdi-calendar-month"
                      label="Date of Birth"
                      height="30"
                      flat
                      rounded
                      solo
                      hide-details
                    ></v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  vid="password"
                  name="Password"
                  :rules="{ required: true, min: 8 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.password"
                    class="register-form p-0 m-0"
                    placeholder="Password"
                    label="Password"
                    color="primary"
                    autocomplete="off"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :error-messages="errors"
                    :success="valid"
                    height="30"
                    flat
                    rounded
                    solo
                    hide-details
                    @click:append="show = !show"
                  >
                  </v-text-field>
                  <ul
                    class="text-caption text-left error--text mt-2"
                    v-if="errors.length > 0"
                  >
                    <li>Min 8 characters</li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Confirm"
                  :rules="{ required: true, confirmed: 'password' }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.confirmation"
                    class="register-form p-0 m-0"
                    placeholder="Confirm your password"
                    label="Confirm"
                    color="primary"
                    autocomplete="off"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                    height="30"
                    flat
                    rounded
                    solo
                    @click:append="show = !show"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="d-flex justify-content-center px-5 pt-0 pb-1">
                <v-checkbox
                  v-model="registerForm.tnc"
                  class="text-caption font-weight-6 mt-2 tnc"
                  color="dark"
                  hide-details
                  style="z-index:1;"
                >
                  <template #label>
                    I agreed with the<span class="tertiary--text ml-2" @click="viewTnC()">terms & conditions</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row class="px-3">
              <v-col cols="6" class="pl-10">
                <v-btn
                  :disabled="isLoading || client"
                  class="signup-btn-cancel text-caption font-weight-6 white--text py-4"
                  block
                  @click="cancel()"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="6" class="pr-10">
                <v-btn
                  :disabled="isLoading || client"
                  color=""
                  class="signup-btn-submit text-caption font-weight-6 white--text py-4"
                  block
                  @click="signup()"
                  >Sign up</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </form>
      <v-alert
        v-if="error"
        class="alert-global text-body-2 white--text p-2"
        type="error"
        transition="fade-transition"
        dense
        >{{ error }}</v-alert
      >
    </ValidationObserver>
    <v-dialog v-model="dialogTnC" width="400px" class="h-100">
      <TnC dialog="true" @close="dialogTnC = false;"></TnC>
    </v-dialog>
    <v-img
      contain
      class="position-absolute"
      position="bottom right"
      src="@/assets/dayonedayone/images/signup-footer-bg.webp"
      style="bottom:40px; right:16px; z-index:0;"
      width="80%"
    ></v-img>
    <div class="text-center position-relative" @click="$router.push({name: 'login', query: { backFullPath: '/signup', redirectFullPath: '/' }})">
      <span class="text-caption font-weight-6">Already a member?</span>
      <a
        class="text-caption tertiary--text font-weight-bold ml-1"
      >
        Log In
      </a>
    </div>
  </v-sheet>
</template>
<style scoped>
.signup-body-sheet {
  background-color: #f7efe8;
  height: 100dvh;
}

.signup-header-sheet, .signup-success-sheet {
  background-color: var(--v-primary);
}

.signup-body-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: none !important;
  background: #f0d2b7 !important;
  z-index: 1 !important;
}

.signup-body-sheet /deep/ .tnc .v-label {
  font-size: 12px !important;
  font-weight: bold;
  color: var(--v-dark);
  margin-top: 7px;
}

.signup-body-sheet /deep/ .signup-btn-cancel, 
.signup-body-sheet /deep/ .signup-btn-submit {
  background-color: var(--v-tertiary) !important;
  color: #fff !important;
  z-index: 1;
}

.register-form ::placeholder {
  color: var(--v-dark) !important;
  font-size: 12px;
  font-weight: 600;
}

.alert-global {
  position: fixed;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(90vw - 48px);
}

@media screen and (min-width: 1024px) {
  .alert-global {
    width: 326px;
  }
}
</style>