<script>
export default {
  props: ['dialog'],
  methods: {
    close() {
      this.$emit('close');
    }
  },
}
</script>
<template>
  <v-card class="tnc text-caption text-justify" color="#f7efe8" elevation="0">
    <v-toolbar
      color="primary"
      height="36"
      v-if="dialog"
    >
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-toolbar-title
        class="text-subtitle-2 font-weight-6 pr-4"
      >
        Terms of Use
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-1"
        icon
        x-small
        @click="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-title class="text-subtitle-1 font-weight-bold pl-2 py-0" v-else>
      Terms Of Use
    </v-card-title>
    <v-card-text
      class="p-0 pt-4"
      :class="{ 'pl-4 pr-6 pb-2': dialog }"
    >
      <div class="text-caption font-weight-6 ml-2 mb-2">General</div>
      <ul>
        <li>
          The Dayone Loyalty Programme (“DLP”) is operated through the Progressive Website Application (PWA) and governed by the following terms and conditions and any other policies, FAQs, guidelines pertaining to the Loyalty Programme as may be in place from time to time (“Terms and Conditions”).
        </li>
        <li>
          Please read these Terms and Conditions carefully. By successfully signing up as a member to the DLP through the PWA, you are deemed to have read, understood, accepted and agree to be bound by each term and condition as contained in these Terms and Conditions. The Terms and Conditions stated herein constitute a legal agreement between you and Dayone.
        </li>
        <li>
          Dayone reserves the right to, at its sole and absolute discretion from time to time without prior notification, amend, modify, update, change and/ or terminate the DLP and/ or all or any portion of the Terms and Conditions (“Amendments”, and “Amended” shall be construed accordingly); and any Amendments will be effective immediately upon posting in the PWA. Each Amended version of the Terms and Conditions shall apply and supersede all previous versions including the hardcopy version (if any). In the event of any inconsistency between these Terms and Conditions and any other material in any medium containing information on the Loyalty Programme, these Terms and Conditions (as Amended) shall prevail.
        </li>
        <li>
          By signing up, you agree that it is your responsibility as a Member to keep yourself updated with the Terms and Conditions (as Amended). Any continued participation as a Member or use of the PWA after any Amendments to the Terms and Conditions shall constitute consent, acceptance and affirmative agreement by the Member to the Terms and Conditions as they exist from time to time, including, without limitation, the amended Terms and Conditions. Dayone shall not be held liable for any loss, damage or expense accrued (regardless of whether Dayone receives prior notice or advice of the possibility of damage) as a result from any Amendments to the DLP or these Terms and Conditions.    
        </li>
        <li>
          If you do not agree to or fall within the Terms and Conditions and wish to discontinue using the DLP, please do not continue using the DLP or PWA and proceed with termination.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Membership</div>
      <ul>
        <li>
          If you are under 18 years, you need your parents' permission before becoming a member and accessing the DLP & PWA.
        </li>
        <li>
          Your Membership is non-transferable. You must not share your username and password with anyone else or allow anyone else to access your DLP or the PWA using your username and password.
        </li>
        <li>
          You are responsible for every use of the DLP that occurs in conjunction with use of your username and password. You must use reasonable efforts to keep your username and password confidential. You must notify us as soon as you become aware of any unauthorized use of your membership, username or password.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Points Collection</div>
      <ul>
        <li>
          Points will be awarded to any Transaction made by a Member at all Dayone outlets in Malaysia.
        </li>
        <li>
          Every Ringgit Malaysia One (RM 1.00) spent by Member tier in a Transaction is equivalent to ONE (1) point (being RM 1.00 = 1 point).
        </li>
        <li>
          Each Transaction receipt shall entitle only one (1) Member to accumulate points.
        </li>
        <li>
          Any Points earned are non-transferable, non-assignable, not for sale, to any third parties and not exchangeable for cash.
        </li>
        <li>
          Members shall be entitled to earn points for each Transaction receipt for dine in and online ordering based on the integer value per Transaction.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Membership Details</div>
      <ul>
        <li>
          Dayone reserves the right to correct the points balance provided in your membership account if believe that a billing or accounting error has occurred regardless of whether notice is provided.
        </li>
        <li>
          Points earned from a Transaction may take up within twenty-four (24) hours to be reflected in the DLP and redemption of points by a Member shall only be allowed with the points that already reflected in the DLP.
        </li>
        <li>
          Points collected shall be on a cumulative basis subject always to any points collected from a Transaction.
        </li>
        <li>
          Dayone reserves the right to refuse awarding any points or to remove any points awarded if Dayone identifies that the points collected were in error or suspects that the points were fraudulently accumulated or in breach of these Terms & Conditions regardless of whether notice is provided.
        </li>
        <li>
          The default validity period for the points is one (1) year from the most recent successful transaction date, and the validity period will be superseded by a new start date upon the completion of a successful transaction.
        </li>
        <li>
          Any unused points will expire if they are not used or in the event you do not perform a successful transaction on the App within the validity period. In such an event, your accumulated points will automatically expire and be automatically forfeited at the absolute discretion of the Company without notice.
        </li>
      </ul>
      
      <div class="text-caption font-weight-6 ml-2 mb-2">Shipping and Delivery</div>
      <ul>
        <li>
          Dayone offers shipping options, and you will be provided with estimated delivery times when you place an order. Delivery charges, if applicable, will be clearly stated during the checkout process.
        </li>
        <li>
          While we strive to meet delivery timelines, we are not responsible for delays caused by unforeseen events, such as weather conditions, transportation issues, or other circumstances beyond our control.
        </li>
      </ul>
      
      <div class="text-caption font-weight-6 ml-2 mb-2">Order Pick Up</div>
      <ul>
        <li>
          Our PWA offers the Products through services by way of order pick-up.
        </li>
        <li>
          For a seamless and convenient experience, we invite you to place your order and make payment through our PWA. Once payment is received, our team will prepare your order for you at your selected Locations.
        </li>
        <li>
          You may go to your selected Locations to pick up the Products. Kindly note that we do not warrant or guarantee the availability, punctuality, reliability of your use of our services at the Locations or on our App. The time for your order pick-up may vary due to unforeseeable events or circumstances including but not limited to server down-time, internet connectivity, weather issues, traffic issues, high demand or peak order periods etc. (“Unforeseeable Event”).
        </li>
        <li>
          We reserve the right to cancel your order if the collection cannot be done due to the Unforeseeable Event. We reserve the right to refund solely through our App at our sole discretion.
        </li>
        <li>
          If you have arranged for an order pick-up, kindly proceed to collect your order at selected Location promptly. If you fail to do so, we reserve the right to cancel your order without refund.
        </li>
        <li>
          In situations where you have scheduled an order pick-up and selected the wrong pick-up locations, we reserve the right to refuse a refund if the order has been prepared by the outlet.
        </li>
      </ul>
      
      <div class="text-caption font-weight-6 ml-2 mb-2">Returns and Refunds</div>
      <ul>
        <li>
          We want you to be satisfied with your purchase. If you receive a damaged or incorrect item, please contact us immediately when you receive your order, and we will guide you through the return process.
        </li>
        <li>
          Our team will submit your order to IPay88 within 7 working days for a refund.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Cancellations</div>
      <ul>
        <li>
          You may cancel your order before it is shipped or prepared for pickup. Please contact our customer service team to request a cancellation.
        </li>
      </ul>
      
      <div class="text-caption font-weight-6 ml-2 mb-2">Redemption</div>
      <ul>
        <li>
          As a member, you will gain access to browse the e-vouchers shown on the rewards catalogue made available in the Application (“Rewards”).
        </li>
        <li>
          All members may use points to redeem Rewards which include, but is not limited to, free drinks, discount vouchers, giveaways, merchandise, or other prescribed products at any Dayone outlets. The type and availability of Rewards will be determined by Dayone at our sole discretion.
        </li>
        <li>
          Notwithstanding the provisions herein, each Reward is subject to additional terms and conditions as well as the specific validity period as stated in the rewards catalogue section of the PWA.
        </li>
        <li>
          Any Rewards earned shall only be redeemed once and at participated Dayone outlet only. Any unutilised Rewards after the applicable expiration date shall be forfeited, not reinstated and not refundable.
        </li>
        <li>
          Members shall pay the price difference if the Transaction value is higher than the amount of Rewards used for redemption. However, in the event where the Transaction value is lower than that of the Rewards, the price difference will not be refunded.
        </li>
        <li>
          We may, at our absolute discretion and without prior notice, reject your redemption request for any reason whatsoever, including without limitation, where:
          <ul>
            <li>
              the Reward is no longer available or out of stock;
            </li>
            <li>
              the points you wish to use for redemption were issued to you in error; or
            </li>
            <li>
              we reasonably believe that redemption may be suspicious, illegal, involves any criminal activity or involves points that have been obtained through dishonest or fraudulent means.
            </li>
          </ul>
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">3rd Parties</div>
      <ul>
        <li>
          The information or content on our PWA may contain links to 3rd party websites or apps that are not under our control and supervision. You are also bound by the relevant terms and conditions of the 3rd party website upon your access to 3rd party website even through the links provided on our PWA. You shall bear sole and full liability in the course of your use of the 3rd party website or PWA.
        </li>
        <li>
          We may engage with a 3rd party service provider for the operation of any services, marketing and promotions or any event at any Location or on our App. You hereby acknowledge and consent our engagement with a 3rd party service provider for operation of service, promotion or event on our PWA; or for any other business and/or legal purposes. We shall not be liable to any claims, losses, demands, liabilities, costs and expenses (including lawyer's fees and costs and expenses related thereto) suffered or incurred by you in connection with the use of 3rd party service provider on our PWA.
        </li>
        <li>
          You further agree to indemnify and defend us from and against all claims, losses, demands, liabilities, costs and expenses (including lawyer's fees and costs and expenses related thereto) suffered or incurred by us as a result of, or in connection with, any 3rd party claims to the extent caused, in whole or in part, by the fraud, gross negligence or willful misconduct on your behalf.
        </li>
      </ul>
      
      <div class="text-caption font-weight-6 ml-2 mb-2">Intellectual Property Rights</div>
      <ul>
        <li>
          We shall own the intellectual property rights, title and interest to any logo, name, brand, marks, design, layout, illustrations, photos, videos, contents, images, sound files and other relevant information (“Information”) in connection to our PWA. Therefore, no rights or license is granted to you to use them.
        </li>
        <li>
          All the Information contained on our PWA is for reference, interaction, and purchase only. It is for your personal use only and the Information shall not be used for commercial purposes.
        </li>
        <li>
          For your use of our PWA and in respect of the PWA and Information, you will not, nor allow third parties on your behalf to:
          <ul>
            <li>
              make and distribute copies;
            </li>
            <li>
              in any way, disseminate, announce, spread, revise, display or sell any of the contents of or the Products described; and
            </li>
            <li>
              attempt to replicate, endorse, copy, reproduce, alter, modify, reverse engineer, disassemble, decompile, transfer, exchange or translate; or create derivative works of any kind whatsoever.
            </li>
          </ul>
        </li>
        <li>
          You agree to indemnify us against any of your unauthorised use of the Information. You shall defend and protect us from any harm caused by your unauthorised use of the Information. You agree that your unauthorised use of the Information may result in our irredeemable losses and in the event of your unauthorised use, we shall reserve the right to apply for any other remedies under the laws of Malaysia.
        </li>
        <li>
          You grant us, without any compensation of any kind to you or others, the exclusive right and license to use, copy, distribute, display, publish, perform, sell, sublicense, modify, edit, adapt, translate, transmit, create derivative works from, and otherwise exploit all images, contents, reviews, comments or contents (“Contents”) uploaded and/or submitted by you in any form, medium or technology in connection to our PWA or the Products, whether now or in the future. Without limiting the foregoing, you agree that, without further consent from you, we may exercise the rights you grant us herein for any and all purposes we deem appropriate, including, without limitation, for the promotion, marketing, and publicising of our services and products. We may, for example, use and publish the Contents on our website or 3rd party websites, in our print, broadcast and digital advertisements, in our marketing materials, and in connection with our promotional events. The right and license you grant us is perpetual, irrevocable, exclusive, royalty-free, unrestricted, worldwide, and transferable without your further consent.
        </li>
        <li>
          You consent and agree that transmission of your Personal Information to us, our agents and/or service providers may be required for some functions of the PWA.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Indemnification</div>
      <ul>
        <li>
          By agreeing to the Terms of Use upon using the Service, you agree that you shall defend, indemnify and hold the Company, its licensors and each such party's parent organizations, subsidiaries, affiliates, officers, directors, members, employees, attorneys and agents harmless from and against any and all claims, suits, damages, costs, lawsuits, fines, penalties, liabilities, expenses (including attorney's fees) arising out of or in connection with (a) your use or misuse of the Service, the Software and/or the PWA; (b) violation of these Terms of Use and/or (c) violation of any rights of a third party. Dayone reserves the right to assume the exclusive defense and control of any matter otherwise subject to your indemnification, in which event you will cooperate in asserting any available defenses. In the event of any third party's claim that the PWA or your possession and use of the Application infringes that third party's intellectual property right, then Dayone will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Telecommunication Failure</div>
      <ul>
        <li>
          The Service, the PWA and/or the Software may be subject to limitations, delays and other problems inherent in the use the internet and electronic communications including the device used by you being faulty, not connected, out of range of mobile signals or functioning incorrectly. The Company is not responsible for any delays, delivery failures, damages or losses resulting from such problems.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Privacy Policy</div>
      <ul>
        <li>
          We value your privacy and any personal information shared with us. Kindly read our Privacy Policy to understand why we need them, how we use them and how we protect them.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Notice</div>
      <ul>
        <li>
          We may give notice on the PWA, our website, our social media channels or by short messaging service or electronic mail to your mobile phone number or email address in our records. All notices shall be deemed to have been given when it is posted on the PWA, our website and social media channels or upon expiration of thirty (30) minutes after it is sent via short messaging service or electronic mail.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Assignment</div>
      <ul>
        <li>
          We reserve our right to assign any of our rights and obligations to a 3rd party without your consent from time to time for any reason.
        </li>
        <li>
          You shall not, without prior written consent of us, assigned any of your rights and obligations to a 3rd party.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Severability</div>
      <ul>
        <li>
          In any case, any provision under the Terms shall be deemed invalid, unenforceable or unlawful, it shall not affect the rest of the Terms or Privacy Policy. The validity, enforceability and lawfulness of all other provisions shall remain impaired.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">No Waiver</div>
      <ul>
        <li>
          If there is a delay in exercising our right or remedy under the Terms, it shall not be deemed as waiver. Any waiver of right and remedy shall be in writing.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Law and Jurisdiction</div>
      <ul>
        <li>
          The Terms shall be interpreted and governed under the laws of Malaysia. You shall agree to submit any dispute or claim to the non-exclusive jurisdiction of the courts of Malaysia.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Updates</div>
      <ul>
        <li>
          We reserve the right to make any unilateral changes, variations, amendments, modifications, alterations and adjustments to any of the Terms, information, material, functions and content of the PWA. Such unilateral changes, variations, amendments, modifications, alterations and adjustments shall be effective upon publication on our PWA. Kindly check the updates of the Terms upon every access and use of our PWA.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Communication</div>
      <ul>
        <li>
          In connection with your use of the PWA, we may from time to time send you announcements, administrative messages, and other information. You may opt out of some of those communications.
        </li>
      </ul>

      <div class="text-caption font-weight-6 ml-2 mb-2">Interpretation</div>
      <ul>
        <li>
          The headings used on the Terms are merely for your convenience on reference. It shall in no way limits the generality and definition on the Terms.
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.tnc {
  background-color: #f7efe8;
}

ul li {
  font-size: 10px;
  margin-bottom: 8px;
}
</style>